import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentWrapper from "../styles/ContentWrapper"

const StyledSection = styled.section`
  width: 100%;
  max-width: 62.5rem;
  margin: 0 auto;
  padding: 0 2.5rem;
  height: auto;
  background: ${({ theme }) => theme.colors.background};

  h1 {
    font-size: 1.5rem;
    margin-bottom: 0;
  }

  a {
    color: #038cfc;
  }
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    margin: 0 auto;
    padding: 0;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`

const NotFoundPage = () => (
  <Layout splashScreen={false}>
    <SEO
      title="404: Not found"
      meta={[{ name: "robots", content: "noindex" }]}
    />
    <StyledSection>
      <StyledContentWrapper>
        <div>
          <h1>Error 404: Page Not Found</h1>
          <Link to="/">Click here to return to the homepage.</Link>
        </div>
      </StyledContentWrapper>
    </StyledSection>
  </Layout>
)

export default NotFoundPage
